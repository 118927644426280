import axios from 'axios'

const BASE_URL = baseUrl;
const CHECK_RETEST = "api/check-retest";
const DOWNLOAD_LINKS = "api/download-links";
const APPLY_GIFTCODE = "api/applyGiftCode";
const SAVE_REPORT = "api/saveReport";
const CREATE_INTENT = "api/createIntent";
const CONFIRM_INTENT = "api/confirmIntent";
const GENERATE_PDF = "api/generatePdf";
const CHECK_RESEARCH_CODE = "api/checkResearchCode";
const UNIFY_REPORTS = "api/unifyReports";
const CONTINUE_REPORT = "/api/continueReport";

export const DOWNLOAD_REPORT = "/reports/download/";

const CREATE_LOT_INTENT = "api/createLotIntent";
const CONFIRM_LOT_INTENT = "api/confirmLotIntent";

export async function makeRequest (route, data, method = 'POST') {
    console.log("ajax", BASE_URL+route)
    const url = BASE_URL+route

    try{
       return axios({
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            },
            method: method,
            url: url,
            data: data
        });

    }catch (e) {

        console.log("error log", e)
        alert("Internet connection error!");
        return false;

    }

}

export async function checkRetest(codeEmail){
    return makeRequest(CHECK_RETEST,{"codeEmail":codeEmail});
}

export async function continueReport(reportId, reportCode){
    return makeRequest(CONTINUE_REPORT,{"reportId":reportId,"reportCode": reportCode});
}

export async function downloadLinks(codeEmail){
    return makeRequest(DOWNLOAD_LINKS,{"codeEmail":codeEmail});
}

export async function saveReport(state, sendEmail = false){
    console.log("saveReport");
    console.log(state);

    let reportData = { 
        researchCode: state.global.researchCode,
        inviteId: state.global.inviteId,
        name: state.userData.name,
        email: state.userData.email,
        demografice: state.userData,
        type: state.global.reportType ? state.global.reportType : null,
        itemsType: state.global.itemsType ? state.global.itemsType : null,
        factors: state.global.customFactors ? state.global.customFactors : [],
        answers: state.answers,
        privacyAccepted: state.global.privacyAccepted,
        status: state.global.reportStatus ? state.global.reportStatus : "unfinished"
    }

    let reportId = null
    if(state.global.reportId){
        reportId = state.global.reportId;
    }

    if(state.global.reportCode){
        reportData['code'] = state.global.reportCode;
    }

    console.log("reportData",reportData);

    return makeRequest(SAVE_REPORT + (reportId ? ("/"+reportId) : ""),{report:reportData,sendEmail:sendEmail});
}

export async function createIntent(reportId,type){
    return makeRequest(CREATE_INTENT+"/"+reportId+"/"+type,{});
}

export async function confirmIntent(reportId){
    return makeRequest(CONFIRM_INTENT+"/"+reportId,{});
}

export async function createLotIntent(lotId){
    return makeRequest(CREATE_LOT_INTENT+"/"+lotId,{});
}

export async function confirmLotIntent(lotId){
    return makeRequest(CONFIRM_LOT_INTENT+"/"+lotId,{});
}

export async function generatePdf(reportId){
    return makeRequest(GENERATE_PDF+"/"+reportId,{});
}

export async function applyGiftCode(reportId, code){
    return makeRequest(APPLY_GIFTCODE+"/"+reportId+"/"+code,{});
}

export async function checkResearchCode(researchCode){
    return makeRequest(CHECK_RESEARCH_CODE,{
        'researchCode': researchCode
    });
}

export async function unifiyReport(goodReport,badReport){
    return makeRequest(UNIFY_REPORTS,{
        'goodReport': goodReport,
        'badReport': badReport
    });
}
