import {Elements,ElementsConsumer} from '@stripe/react-stripe-js';
import React from 'react';
import CheckoutForm from './CheckoutForm';


const InjectedCheckoutForm = (props) => {
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <CheckoutForm
                    amount={props.amount}
                    hidePaymentForm={props.hidePaymentForm}
                    handlePaymentSuccess={props.handlePaymentSuccess}
                    clientSecret={props.clientSecret}
                    elements={elements}
                    stripe={stripe} />
            )}
        </ElementsConsumer>
    );
};

class PaymentForm extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id={"stripeEl"}>
                <Elements stripe={this.props.stripe}>
                    <InjectedCheckoutForm
                        amount={this.props.amount}
                        hidePaymentForm={this.props.hidePaymentForm}
                        handlePaymentSuccess={this.props.handlePaymentSuccess}
                        clientSecret={this.props.clientSecret} />
                </Elements>
            </div>
        );
    }
}

export default PaymentForm